// TAKEN FROM https://developer.mozilla.org/en-US/docs/Web/API/document/cookie
// 1.3 version of angular-cookies does not support setting path values which we require, so using the MDN version
// TODO: upgrade once angular 1.4 is upgraded
export function escapeCookieKey(sKey) {
  return encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&');
}

export function getCookie(sKey) {
  if (!sKey) {
    return null;
  }
  const searchPattern = new RegExp(
    '(?:(?:^|.*;)\\s*' + escapeCookieKey(sKey) + '\\s*\\=\\s*([^;]*).*$)|^.*$',
  );
  return (
    decodeURIComponent(document.cookie.replace(searchPattern, '$1')) || null
  );
}

export function setCookie(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
  if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
    return false;
  }
  let sExpires = '';
  if (vEnd) {
    switch (vEnd.constructor) {
      case Number:
        sExpires =
          vEnd === Infinity
            ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
            : '; max-age=' + vEnd;
        break;
      case String:
        sExpires = '; expires=' + vEnd;
        break;
      case Date:
        sExpires = '; expires=' + vEnd.toUTCString();
        break;
    }
  }
  document.cookie =
    encodeURIComponent(sKey) +
    '=' +
    encodeURIComponent(sValue) +
    sExpires +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '') +
    (bSecure ? '; secure' : '');
  return true;
}

export function removeCookie(sKey, sPath, sDomain) {
  if (!this.hasItem(sKey)) {
    return false;
  }
  document.cookie =
    encodeURIComponent(sKey) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '');
  return true;
}

export function hasCookie(sKey) {
  if (!sKey) {
    return false;
  }
  return new RegExp('(?:^|;\\s*)' + escapeCookieKey(sKey) + '\\s*\\=').test(
    document.cookie,
  );
}

export function cookieKeys() {
  const aKeys = document.cookie
    .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
    .split(/\s*(?:\=[^;]*)?;\s*/);
  return aKeys.map(decodeURIComponent);
}
