import { hasCookie } from '../app/utils/cookie-utils';

const hostname = window.location.hostname;
export const ENV_DEVELOPMENT =
  (window.config && window.config.devMode) ||
  !/\.qualified\.io$/i.test(hostname);
export const ENV_STAGING = !ENV_DEVELOPMENT && /^staging/i.test(hostname);
export const ENV_PREVIEW =
  !ENV_DEVELOPMENT && !ENV_STAGING && /^preview/i.test(hostname);
export const ENV_PRODUCTION = !ENV_DEVELOPMENT && !ENV_STAGING && !ENV_PREVIEW;
export const ENV_QUALIFIED = ENV_DEVELOPMENT
  ? 'development'
  : ENV_STAGING
    ? 'staging'
    : ENV_PREVIEW
      ? 'preview'
      : 'production';

const loc = window.location;
if (!loc.origin) {
  loc.origin =
    loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port : '');
}
const Modernizr = window && window.Modernizr;
if (Modernizr) {
  let ua = window.navigator.userAgent;
  let mac = ua.includes('Mac OS X');
  // From StackOverflow: https://stackoverflow.com/a/19868056/145185
  // This is the "correct" way to detect IE and not Edge, apparently
  let msie = 'ActiveXObject' in window;
  Modernizr.addTest({
    'qualified-dev': ENV_DEVELOPMENT,
    'qualified-staging': ENV_STAGING,
    'qualified-preview': ENV_PREVIEW,
    'qualified-production': ENV_PRODUCTION,
    'qualified-logged-in-candidate': hasCookie('candidate_login'),
    'qualified-logged-in-employer': hasCookie('team_member_login'),
    'mac-os': mac,
    msie: msie,
  });
}
