import { ENV_QUALIFIED } from './environment-flags';
import '../../../node_modules/@sentry/browser/build/bundle.js';

const IGNORE_REPEATED_EVENT_TIMEOUT = 60000; // ignore the same error if it occurs within 1 minute
let lastEvenTime = 0;
let lastEventMessage = null;

// Configure Sentry
window.Sentry.init({
  dsn: 'https://bab79b2e49f042e5a78c2d09a182d99f@sentry.io/67625',
  environment: ENV_QUALIFIED,
  release: window.config && window.config.release,

  // The following sections are used to filter out errors that aren't related to our apps
  beforeSend(event) {
    try {
      const exception = event.exception && event.exception.values[0];
      const message = event.message || exception.value;
      const stacktrace = event.stack || JSON.stringify(exception.stacktrace);
      // ignore any undefined error associated with codemirror
      if (
        event &&
        /undefined/.test(message) &&
        /\/shared-codemirror/.test(stacktrace)
      ) {
        return null;
      }
      // ignore events that repeat with the same message within 1 minute (reduces noise from $digest or
      if (
        Date.now() - lastEvenTime < IGNORE_REPEATED_EVENT_TIMEOUT &&
        lastEventMessage === message
      ) {
        return null;
      }
      // track event if we didn't ignore it
      lastEvenTime = Date.now();
      lastEventMessage = message;
    } catch (err) {}
    return event;
  },
  ignoreErrors: [
    // Ignore missing lines in CodeMirror
    /There is no line \d+ in the document/,

    // Ignore promise rejections
    'UnhandledRejection',

    // Ignore unhandled network errors
    /Failed to fetch/,
    /Network Error/,

    // Ignore dispose errors from CodemirrorWorkspace
    /Pending response rejected since connection got disposed/,

    //////////////////////////////////////////////////////////
    // Community provided ignores
    // https://gist.github.com/impressiver/5092952
    //////////////////////////////////////////////////////////

    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    //////////////////////////////////////////////////////////
    // Community provided ignores
    // https://gist.github.com/impressiver/5092952
    //////////////////////////////////////////////////////////

    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
